.allTransactionsTable .p-datatable-tbody > tr > td{
    /*background: red !important;*/
    /*padding: 0px !important;*/
    /*width:fit-content !important;*/
    /*height: 50px !important;*/
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 700;
    white-space: nowrap; /* Prevent cell content from wrapping */

    border-color: rgb(239 239 247/1) !important;
}

.allTransactionsTable .p-datatable-header{
    background:white !important;
    border-color: rgb(239 239 247/1) !important;

}

.allTransactionsTable .p-datatable .p-datatable-thead > tr > th {
    background: white !important;
}

.allTransactionsTable  .p-datatable-thead > tr > th {
    /*padding-left: 0px !important;*/
    /*padding-right: 10rem !important;*/
    background:white !important;
    /*border: none !important;*/
    white-space: nowrap; /* Prevent header text from wrapping */
    /*border-color: rgb(239 239 247/1) !important;*/
}