.popup_menu_right{
    background: red !important;
}

.accountSelectionMenu .p-menuitem	{
    font-weight:bold;
}

.searchAccount .p-inputtext{
    padding: 4px ! important;
    width:20em;
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-size:14px;
    color: #8b93a7;
}