@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.popup_menu_right{
    background: red !important;
}

.accountSelectionMenu .p-menuitem	{
    font-weight:bold;
}

.searchAccount .p-inputtext{
    padding: 4px ! important;
    width:20em;
    font-family: Roboto,Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-size:14px;
    color: #8b93a7;
}
.customPanel {
    z-index: 9999 !important;
}
.multiSelect{
    border-radius: 10px !important;
    /*padding: 0.5rem 0.75rem !important;*/
    border:0.0625rem solid #d2d6da !important;
    font-size: 0.875rem!important;
    line-height: 1.4!important;
}

/*.multiSelect .p-multiselect .p-focus{*/
/*    border-color: red !important;*/
/*}*/


.my-datepicker{
    z-index: 2000 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    font-size:10px;
}

.xx .p-datatable .p-datatable-thead{
  background: none !important;
}

.dataTableRow > td {
  height: 50px !important;
}

.allTransactionsTable .p-datatable-tbody > tr > td{
  /*background: red !important;*/
  /*padding: 0px !important;*/
  /*width:fit-content !important;*/
  /*height: 50px !important;*/

  white-space: nowrap; /* Prevent cell content from wrapping */

  border-color: rgb(239 239 247/1) !important;
}
.allTransactionsTable  .p-datatable-thead > tr > th{
  /*padding-left: 0px !important;*/
  /*padding-right: 10rem !important;*/
  white-space: nowrap; /* Prevent header text from wrapping */
  border-color: rgb(239 239 247/1) !important;
  padding: 1rem 1rem !important;
}

.full-width-tabmenu .p-tabmenu-nav {
  display: flex;
  width: 100%;
  border: none !important;

  font-width: "bold" !important;
  font-family: "Roboto" !important;

}

.full-width-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  color:rgb(14, 14, 82) !important;

}
.full-width-tabmenu p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
  border-color: rgb(14, 14, 82) !important

}


.full-width-tabmenu  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  border-color: rgb(14, 14, 82) !important
}

.listStyle {
  max-height: 40px !important;
}

.tokenLogoAvatar {
  cursor: pointer;
  max-width: 30px;
  margin-right: 10px;
  min-width: 0px !important;
}

.tokenLogo{
  width: 100% !important;
  height: auto !important;
}

.primaryTextTable {
  font-size:14px !important;
}

.secondaryText{
  font-size:10px !important;
}
.xx .p-datatable .p-datatable-thead{
    background: none !important;
}

.dataTableRow > td {
    height: 50px !important;
}

.allTransactionsTable .p-datatable-tbody > tr > td{
    /*background: red !important;*/
    /*padding: 0px !important;*/
    /*width:fit-content !important;*/
    /*height: 50px !important;*/

    white-space: nowrap; /* Prevent cell content from wrapping */
    border-color: rgb(239 239 247/1) !important;
}
.allTransactionsTable  .p-datatable-thead > tr > th{
    /*padding-left: 0px !important;*/
    /*padding-right: 10rem !important;*/
    white-space: nowrap; /* Prevent header text from wrapping */
    border-color: rgb(239 239 247/1) !important;
}

.full-width-tabmenu .p-tabmenu-nav {
    display: flex;
    width: 100%;
    border: none !important;

    font-width: "bold" !important;
    font-family: "Roboto" !important;

}

.full-width-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    color:rgb(14, 14, 82) !important;

}
.full-width-tabmenu p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
    border-color: rgb(14, 14, 82) !important

}


.full-width-tabmenu  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    border-color: rgb(14, 14, 82) !important
}

.allTransactionsTable .p-datatable .p-datatable-header{
    border-color:white;
}

.allTransactionsTable .p-column-title	 {
    color: rgb(14, 14, 82) !important;
    font-family: Roboto;
}


.allTransactionsTable .p-datatable-tbody > tr > td{
    /*background: red !important;*/
    /*padding: 0px !important;*/
    /*width:fit-content !important;*/
    /*height: 50px !important;*/
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 700;
    white-space: nowrap; /* Prevent cell content from wrapping */

    border-color: rgb(239 239 247/1) !important;
}

.allTransactionsTable .p-datatable-header{
    background:white !important;
    border-color: rgb(239 239 247/1) !important;

}

.allTransactionsTable .p-datatable .p-datatable-thead > tr > th {
    background: white !important;
}

.allTransactionsTable  .p-datatable-thead > tr > th {
    /*padding-left: 0px !important;*/
    /*padding-right: 10rem !important;*/
    background:white !important;
    /*border: none !important;*/
    white-space: nowrap; /* Prevent header text from wrapping */
    /*border-color: rgb(239 239 247/1) !important;*/
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'DM Sans', sans-serif !important;
        font-size: 16px;
        font-feature-settings: 'kern' !important;
        -webkit-font-smoothing: antialiased;
        letter-spacing: -0.5px;
    }
}

input.defaultCheckbox::before {
    /*content: url(../src/assets/svg/checked.svg);*/
    color: white;
    opacity: 0;
    height: 16px;
    width: 16px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
    opacity: 1;
}
.accountSelectionMenu{
    z-index: 1700 !important;
    /*width:300px !important;*/
}
