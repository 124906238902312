.xx .p-datatable .p-datatable-thead{
  background: none !important;
}

.dataTableRow > td {
  height: 50px !important;
}

.allTransactionsTable .p-datatable-tbody > tr > td{
  /*background: red !important;*/
  /*padding: 0px !important;*/
  /*width:fit-content !important;*/
  /*height: 50px !important;*/

  white-space: nowrap; /* Prevent cell content from wrapping */

  border-color: rgb(239 239 247/1) !important;
}
.allTransactionsTable  .p-datatable-thead > tr > th{
  /*padding-left: 0px !important;*/
  /*padding-right: 10rem !important;*/
  white-space: nowrap; /* Prevent header text from wrapping */
  border-color: rgb(239 239 247/1) !important;
  padding: 1rem 1rem !important;
}

.full-width-tabmenu .p-tabmenu-nav {
  display: flex;
  width: 100%;
  border: none !important;

  font-width: "bold" !important;
  font-family: "Roboto" !important;

}

.full-width-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  color:rgb(14, 14, 82) !important;

}
.full-width-tabmenu p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
  border-color: rgb(14, 14, 82) !important

}


.full-width-tabmenu  .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  border-color: rgb(14, 14, 82) !important
}

.listStyle {
  max-height: 40px !important;
}

.tokenLogoAvatar {
  cursor: pointer;
  max-width: 30px;
  margin-right: 10px;
  min-width: 0px !important;
}

.tokenLogo{
  width: 100% !important;
  height: auto !important;
}

.primaryTextTable {
  font-size:14px !important;
}

.secondaryText{
  font-size:10px !important;
}