.customPanel {
    z-index: 9999 !important;
}
.multiSelect{
    border-radius: 10px !important;
    /*padding: 0.5rem 0.75rem !important;*/
    border:0.0625rem solid #d2d6da !important;
    font-size: 0.875rem!important;
    line-height: 1.4!important;
}

/*.multiSelect .p-multiselect .p-focus{*/
/*    border-color: red !important;*/
/*}*/

