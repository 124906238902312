@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'DM Sans', sans-serif !important;
        font-size: 16px;
        font-feature-settings: 'kern' !important;
        -webkit-font-smoothing: antialiased;
        letter-spacing: -0.5px;
    }
}

input.defaultCheckbox::before {
    /*content: url(../src/assets/svg/checked.svg);*/
    color: white;
    opacity: 0;
    height: 16px;
    width: 16px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
    opacity: 1;
}